import API from "common/api";
import {
  DeliveryCharge,
  DeliveryChargeRequestBody,
  getDeliveryDetails,
} from "./DeliveryModels";
import { encryptJson } from "common/utils/react-ec-utils";
export function getDeliveryChargeAPI(payload: DeliveryChargeRequestBody) {
  const requestPayload: DeliveryCharge = {
    pickupDetails: payload.pickupDetails,
    dropDetails: payload.dropDetails,
    locationId: payload.locationId,
    deliveryProviderId: payload.locationProviderId,
    receiverDetails:payload.receiverDetails,
    senderDetails: payload.senderDetails,
    tip: Math.floor(payload.tip) 
  };
 // console.log("requestPayload", requestPayload);
  
  const data = encryptJson(requestPayload);
  const ecnLocationProviderId = encryptJson(payload.locationProviderId);

  return API({
    method: "post",
    url: `/delivery/quote?deliveryProviderId=${ecnLocationProviderId}`,
    data:{"data":data}
  });
}

export function dunzoOrderTrackingAPI(payload: getDeliveryDetails) {
  return API({
    method: "get",
    url: `/delivery/status?deliveryProviderId=${payload.DeliveryProviders}&orderId=${payload?.orderId}`,
  });
}

// Instore Valet app tracking
export function getValetLocation(deliveryStaffId: string) {
  return API({
    method: "get",
    url: `/valet/getValetLocation?deliveryStaffId=${deliveryStaffId}`,
  });
}
