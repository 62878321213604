import { RootState } from "common/rootReducer";
import React, { Fragment, useCallback } from "react";
import { ReactComponent as PlusIcon } from "../../common/assets/svg/pluss.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import "./styles.css";

export interface PriceTextProps {
  price: any;
  size?: "big" | "normal";
  isBill?: boolean;
  isBase?: boolean;
  isMenu?: boolean;
}

const PriceText = (props: PriceTextProps) => {
  const location = useLocation();

  // const restaurantCountry = useSelector((state: RootState) =>
  //   location.pathname.includes("orderSummary")
  //     ? state.restaurant.activeOrderRestaurantDetails &&
  //       state.restaurant.activeOrderRestaurantDetails.country
  //     : state.restaurant.currentRestaurantDetail.country
  // );

  const restaurantCountry = useSelector(
    (state: RootState) => state.restaurant.currentResCountry
  );

  const getCountryPrice = useCallback(
    (price: number) => {
      if (restaurantCountry == "US" || props.isBill) {
        return Number(price).toFixed(2);
      } else {
        if (props.isMenu) {
          return parseFloat(price.toString()).toFixed(2);
        } else {
          return (Number(price).toFixed(2)).toString();
        }
      }
    },
    [restaurantCountry]
  );

  return (
    <span className={props.size && props.size == "big" ? "price big" : "price"}>
      {+props.price < 0 && <span>-</span>}
      {props.size && props.size == "big" ? (
        <Fragment>
          <p className="money-symbol">
            {restaurantCountry == "US" ? (
              <Fragment>&#36;</Fragment>
            ) : restaurantCountry == "IN" ? (
              <Fragment> &#x20B9;</Fragment>
            ):""}
          </p>
          <span className="price-digit">{props.price ? getCountryPrice(props.price) : 0.00}</span>
          {/* <span className="money-sup">00</span> */}
        </Fragment>
      ) : (
        <Fragment>
          {/* {props.price > 0 ? (
            <> */}
          {props.isBase && <PlusIcon />}
          <div className="price-container">
            <p className="money-symbol">
              {restaurantCountry == "US" ? (
                <Fragment>&#36;</Fragment>
              ) : restaurantCountry == "IN"?(
                <Fragment> &#x20B9;</Fragment>
              ):''}
            </p>
            <span className="price-digit">{props.price ? getCountryPrice(props.price) : 0}</span>
          </div>
          {/* </>
          ) : null} */}

          {/* <span className="money-sup">00</span> */}
        </Fragment>
      )}
    </span>
  );
};

export default PriceText;
