import { typedAction } from "../actionTypes";
import { LocationInfo, LocationState } from "../location/locationModels";
import {
  RESTAURANT_DETAIL_REQUEST,
  RESTAURANT_DETAIL_FAILURE,
  RESTAURANT_DETAIL_SUCCESS,
  RESTAURANT_MENU_DETAIL_REQUEST,
  RESTAURANT_MENU_DETAIL_FAILURE,
  RESTAURANT_MENU_DETAIL_SUCCESS,
  FEEDBACK_DETAIL_REQUEST,
  FEEDBACK_DETAIL_FAILURE,
  FEEDBACK_DETAIL_SUCCESS,
  SUBMIT_FEEDBACK_DETAIL_REQUEST,
  SUBMIT_FEEDBACK_DETAIL_FAILURE,
  SUBMIT_FEEDBACK_DETAIL_SUCCESS,
  STORE_MENU_ITEM,
  RESTAURANTS_REQUEST,
  RESTAURANTS_FAILURE,
  RESTAURANTS_SUCCESS,
  STORE_SELECTED_LOCATION,
  CLEAR_SELECTED_LOCATION,
  REFRESH_MENU_DETAIL,
  STORE_RESTAURANT_SERVING_LOCATIONS,
  SERVING_RESTAURANTS_REQUEST,
  SERVING_RESTAURANTS_FAILURE,
  SERVING_RESTAURANTS_SUCCESS,
  RESTAURANT_DETAIL_LOCATION_ID_REQUEST,
  RESTAURANT_DETAIL_LOCATION_ID_SUCCESS,
  RESTAURANT_DETAIL_LOCATION_ID_CLEAR,
  ACTIVE_ORDER_RESTAURANT_REQUEST,
  ACTIVE_ORDER_RESTAURANT_CLEAR,
  ACTIVE_ORDER_RESTAURANT_SUCCESS,
  GET_MASTER_HOME_PAGE_DETAILS,
  GET_MASTER_HOME_PAGE_DETAILS_SUCCESS,
  GET_MASTER_HOME_PAGE_DETAILS_ERROR,
  CLEAR_RESTAURANT_DETAIL,
  CLEAR_FEEDBACK_DATA,
  GET_RESTAURANT_TABLE_DETAILS,
  GET_RESTAURANT_TABLE_DETAILS_SUCCESS,
  GET_RESTAURANT_TABLE_DETAILS_FAILED,
  RESTAURANT_ORDER_TYPE_SWITCH_SUCCESS,
  UPDATE_CD_GUEST_COUNT,
  RESTAURANT_MENU_DETAIL_REQUEST_BY_DATE,
  UPDATE_RESTAURANT_STATUS,
} from "./restaurantContants";
import {
  RestaurantDetail,
  RestaurantDetailFailResult,
  RestaurantMenuItem,
  RestaurantMenuDetailFailResult,
  SubmitFeedbackDetail,
  FeedbackQuestion,
  RestaurantMenuRequest,
  RestaurantMenuResponse,
  RestaurantItemDetail,
  RestaurantsRequestParams,
  RestaurantDetailRequest,
  RestaurantDetailByLocationIdRequest,
  RestaurantTableDetails,
  restarauntPayload,
} from "./restaurantModels";

// Store selected location
export function storeSelectedLocation(details: LocationInfo) {
  return typedAction(STORE_SELECTED_LOCATION, details);
}

export function clearSelectedLocation() {
  return typedAction(CLEAR_SELECTED_LOCATION, "");
}

// Get Restaurants
export function getRestaurantsRequest(
  locationParams: RestaurantsRequestParams
) {
  return typedAction(RESTAURANTS_REQUEST, locationParams);
}

export function failedRestaurants(detail: RestaurantDetailFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(RESTAURANTS_FAILURE, detail);
}

export function successRestaurants(details: RestaurantItemDetail[]) {
  return typedAction(RESTAURANTS_SUCCESS, details);
}

// Get Serving Locations
export function getServingLocations(locationParams: RestaurantsRequestParams) {
  return typedAction(SERVING_RESTAURANTS_REQUEST, locationParams);
}

export function failedGetServingLocations(detail: RestaurantDetailFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(SERVING_RESTAURANTS_FAILURE, detail);
}

export function successGetServingLocations(details: RestaurantItemDetail[]) {
  return typedAction(SERVING_RESTAURANTS_SUCCESS, details);
}

export function storeServingLocations(details: RestaurantItemDetail[]) {
  return typedAction(STORE_RESTAURANT_SERVING_LOCATIONS, details);
}

// Restaurant Details
export function getRestaurantDetails(slug: string, merchantType?: string) {
  // Store Merchant Type
  // merchantType && storeMerchantType(merchantType);

  return typedAction(RESTAURANT_DETAIL_REQUEST, {
    slug: slug,
    merchantType: merchantType,
  } as RestaurantDetailRequest);
}

// Restaurant Details
export function getRestaurantDetailsWithCallback(details: restarauntPayload) {
  // Store Merchant Type
  // merchantType && storeMerchantType(merchantType);

  return typedAction(RESTAURANT_DETAIL_REQUEST, {
    slug: details?.slug,
    merchantType: details?.merchantType,
    sagaCallBack:details?.sagaCallBack
  } as RestaurantDetailRequest);
}

// Active order Restaurant

export function getActiveOrderRestaurant(
  details: RestaurantDetailByLocationIdRequest
) {
  return typedAction(ACTIVE_ORDER_RESTAURANT_REQUEST, details);
}

export function getActiveOrderRestaurantSuccess(details: RestaurantDetail) {
  return typedAction(ACTIVE_ORDER_RESTAURANT_SUCCESS, details);
}

export function clearActiveOrderRestaurant() {
  return typedAction(ACTIVE_ORDER_RESTAURANT_CLEAR, "");
}

export function getRestaurantDetailsByLocationId(
  details: RestaurantDetailByLocationIdRequest
) {
  return typedAction(RESTAURANT_DETAIL_LOCATION_ID_REQUEST, details);
}

export function getRestaurantDetailsByLocationIdSuccess({details,isEventOrder}) {
  return typedAction(RESTAURANT_DETAIL_LOCATION_ID_SUCCESS, {details,isEventOrder});
}

export function clearRestaurantDetailsByLocationId() {
  return typedAction(RESTAURANT_DETAIL_LOCATION_ID_CLEAR, "");
}

export function failedRestaurantDetails(detail: RestaurantDetailFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(RESTAURANT_DETAIL_FAILURE, detail);
}

export function successRestaurantDetails({detail,isEventOrder}) {
  return typedAction(RESTAURANT_DETAIL_SUCCESS, {detail,isEventOrder});
}

// Restaurant Menu Details
export function refreshRestaurantMenuDetails() {
  return typedAction(REFRESH_MENU_DETAIL, "");
}

export function clearMenuDetails() {
  return typedAction(CLEAR_RESTAURANT_DETAIL, "");
}

export function getRestaurantMenuDetails(details: RestaurantMenuRequest) {
  return typedAction(RESTAURANT_MENU_DETAIL_REQUEST, details);
}

export function getRestaurantMenuDetailsbyDate(details: RestaurantMenuRequest) {
  return typedAction(RESTAURANT_MENU_DETAIL_REQUEST_BY_DATE, details);
}

export function updateCdGuestCount(guestCount: Number) {
  return typedAction(UPDATE_CD_GUEST_COUNT, guestCount);
}

export function failedRestaurantMenuDetails(
  detail: RestaurantMenuDetailFailResult
) {
  // Replace any with Network Data Fail Data format
  return typedAction(RESTAURANT_MENU_DETAIL_FAILURE, detail);
}

export function successRestaurantMenuDetails(detail: RestaurantMenuResponse) {
  return typedAction(RESTAURANT_MENU_DETAIL_SUCCESS, detail);
}

export function orderTypeSwitch(detail: any) { 
  return typedAction(RESTAURANT_ORDER_TYPE_SWITCH_SUCCESS, detail);
}


// Survey/Feedback Details 
export function getFeedbackDetails() {
  return typedAction(FEEDBACK_DETAIL_REQUEST, "");
}
export function failedFeedbackDetails() {
  // Replace any with Network Data Fail Data format
  return typedAction(FEEDBACK_DETAIL_FAILURE, "");
}
export function successFeedbackDetails(details: FeedbackQuestion[]) {
  return typedAction(FEEDBACK_DETAIL_SUCCESS, details);
}

export function clearFeedbackData() {
  return typedAction(CLEAR_FEEDBACK_DATA, "");
}

// Survey / Feedback submit
export function submitFeedbackDetails(details: SubmitFeedbackDetail) {
  return typedAction(SUBMIT_FEEDBACK_DETAIL_REQUEST, details);
}
export function failedSubmitFeedbackDetails() {
  // Replace any with Network Data Fail Data format
  return typedAction(SUBMIT_FEEDBACK_DETAIL_FAILURE, "Failed submit");
}
export function successSubmitFeedbackDetails() {
  return typedAction(
    SUBMIT_FEEDBACK_DETAIL_SUCCESS,
    "Feedback Submitted Successfully"
  );
}

// Master Restaurant Home Page
export function getMasterHomePageDetails(slug: string) {
  return typedAction(GET_MASTER_HOME_PAGE_DETAILS, slug);
}

export function getMasterHomePageDetailsSuccess(detail: RestaurantDetail) {
  return typedAction(GET_MASTER_HOME_PAGE_DETAILS_SUCCESS, detail);
}

export function getMasterHomePageDetailsFailed() {
  return typedAction(GET_MASTER_HOME_PAGE_DETAILS_ERROR, "");
}

// Get Restaurant Table Details
export function getRestaurantTableDetailsRequest(resId: string) {
  return typedAction(GET_RESTAURANT_TABLE_DETAILS, resId);
}

export function getRestaurantTableDetailsSuccess(
  tableDetails: RestaurantTableDetails[]
) {
  return typedAction(GET_RESTAURANT_TABLE_DETAILS_SUCCESS, tableDetails);
}

export function getRestaurantTableDetailsFailed() {
  return typedAction(GET_RESTAURANT_TABLE_DETAILS_FAILED, "");
}


export function updateRestaurantDetailsLoaderStatus(status: boolean) {
  return typedAction(UPDATE_RESTAURANT_STATUS, status);
}

export type restaurantActions = ReturnType<
  | typeof getRestaurantsRequest
  | typeof clearSelectedLocation
  | typeof failedRestaurants
  | typeof successRestaurants
  | typeof getRestaurantDetailsByLocationId
  | typeof getRestaurantDetailsByLocationIdSuccess
  | typeof clearRestaurantDetailsByLocationId
  | typeof getRestaurantDetails
  | typeof getRestaurantDetailsWithCallback
  | typeof failedRestaurantDetails
  | typeof successRestaurantDetails
  | typeof getRestaurantMenuDetails
  | typeof getRestaurantMenuDetailsbyDate
  | typeof failedRestaurantMenuDetails
  | typeof successRestaurantMenuDetails
  | typeof getFeedbackDetails
  | typeof failedFeedbackDetails
  | typeof successFeedbackDetails
  | typeof submitFeedbackDetails
  | typeof failedSubmitFeedbackDetails
  | typeof successSubmitFeedbackDetails
  | typeof storeSelectedLocation
  | typeof refreshRestaurantMenuDetails
  | typeof getServingLocations
  | typeof failedGetServingLocations
  | typeof successGetServingLocations
  | typeof storeServingLocations
  | typeof getActiveOrderRestaurant
  | typeof getActiveOrderRestaurantSuccess
  | typeof clearActiveOrderRestaurant
  | typeof getMasterHomePageDetails
  | typeof getMasterHomePageDetailsSuccess
  | typeof getMasterHomePageDetailsFailed
  | typeof clearMenuDetails
  | typeof clearFeedbackData
  | typeof getRestaurantTableDetailsRequest
  | typeof getRestaurantTableDetailsSuccess
  | typeof getRestaurantTableDetailsFailed
  | typeof orderTypeSwitch
  | typeof updateCdGuestCount
  | typeof updateRestaurantDetailsLoaderStatus
>;
